var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"main pa-4"},[_c('v-row',{staticClass:"query"},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.btnLoading1},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("获取已分配的DNS清单")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.btnLoading2},on:{"click":function($event){return _vm.getList(2)}}},[_vm._v("下发DNS分配清单")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.download}},[_vm._v("下载映射表")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.btnLoading4},on:{"click":function($event){return _vm.getRules0(0, true)}}},[_vm._v("生成映射表")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.btnLoading5},on:{"click":function($event){return _vm.getRules1(1, true)}}},[_vm._v("下发映射表")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.btnLoading7},on:{"click":function($event){return _vm.post()}}},[_vm._v("下发IPv6资源池")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.btnLoading6},on:{"click":function($event){return _vm.gend()}}},[_vm._v("生成IPv6资源池")])],1),_c('v-row',{staticClass:"mt-4"},[_c('v-btn',{staticClass:"ml-2 blue-grey darken-2",attrs:{"dark":"","loading":_vm.btnLoading3},on:{"click":function($event){return _vm.postRules()}}},[_vm._v("一键下发")])],1),(!_vm.status && !_vm.flag && _vm.index < 3)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataList,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}}],null,false,1744913539)})],1):_vm._e(),(_vm.status && !_vm.flag)?_c('div',{staticClass:"mt-8"},[_c('div',[(_vm.loading1)?_c('v-progress-linear',{attrs:{"height":"15"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}},[_c('strong',[_vm._v(_vm._s(_vm.progress)+"%")])]):_vm._e()],1),(!_vm.loading1)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-spaceer',{attrs:{"name":"spaceer"}})],1),_c('v-col',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"green"},attrs:{"size":"80"}},[_vm._v("mdi-checkbox-marked-circle")]),_c('div',{staticClass:"text-h5 black--text"},[_vm._v(_vm._s(_vm.message))])],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-spaceer',{attrs:{"name":"spaceer"}})],1)],1)],1)],1):_vm._e()],1):_vm._e(),(_vm.flag && _vm.step > 0)?_c('div',{staticClass:"mt-8"},[_c('v-stepper',{attrs:{"value":_vm.step,"flat":""}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.step > 1}},[_vm._v(" 生成映射表 ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2","complete":_vm.step > 2}},[_vm._v(" 下发映射表")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3","complete":_vm.step == 3}},[_vm._v("下发IPv6资源池")])],1)],1),_c('Result',{attrs:{"input":{
        flag: _vm.flag,
        progress: _vm.progress,
        message: _vm.message,
        step: _vm.step,
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }