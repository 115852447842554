<template>
  <v-card class="main pa-4">
    <v-row class="query">
      <!-- <v-btn
        color="primary"
        class="ml-2 hidden"
        @click="getList(0)"
        :loading="btnLoading"
        >更新dns清单</v-btn
      > -->
      <v-btn
        color="primary"
        class="ml-2"
        @click="getList(1)"
        :loading="btnLoading1"
        >获取已分配的DNS清单</v-btn
      >
      <v-btn
        color="primary"
        class="ml-2"
        @click="getList(2)"
        :loading="btnLoading2"
        >下发DNS分配清单</v-btn
      >
      <v-btn color="primary" class="ml-2" @click="download">下载映射表</v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        @click="getRules0(0, true)"
        :loading="btnLoading4"
        >生成映射表</v-btn
      >
      <v-btn
        color="primary"
        class="ml-2"
        @click="getRules1(1, true)"
        :loading="btnLoading5"
        >下发映射表</v-btn
      >

      <v-btn color="primary" class="ml-2" @click="post()" :loading="btnLoading7"
        >下发IPv6资源池</v-btn
      >
      <v-btn color="primary" class="ml-2" @click="gend()" :loading="btnLoading6"
        >生成IPv6资源池</v-btn
      >
    </v-row>
    <v-row class="mt-4"
      ><v-btn
        class="ml-2 blue-grey darken-2"
        dark
        @click="postRules()"
        :loading="btnLoading3"
        >一键下发</v-btn
      >
    </v-row>

    <div v-if="!status && !flag && index < 3">
      <v-data-table :headers="headers" :items="dataList" :loading="loading">
        <template v-slot:item.no="{ item, index }">
          {{ index + 1 }}
        </template>
      </v-data-table>
    </div>
    <div class="mt-8" v-if="status && !flag">
      <div>
        <v-progress-linear v-if="loading1" v-model="progress" height="15">
          <strong>{{ progress }}%</strong>
        </v-progress-linear>
      </div>

      <v-card flat v-if="!loading1">
        <v-card-text>
          <v-row>
            <v-col sm="2">
              <v-spaceer name="spaceer"></v-spaceer>
            </v-col>
            <v-col class="text-center">
              <v-icon style="color: green" size="80"
                >mdi-checkbox-marked-circle</v-icon
              >
              <div class="text-h5 black--text">{{ message }}</div>
            </v-col>
            <v-col sm="2">
              <v-spaceer name="spaceer"></v-spaceer>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col sm="2">
              <v-spaceer></v-spaceer>
            </v-col>
            <v-col class="text-center">
              <v-btn color="primary" class="ml-2" @click="goTo(0)"
                >查看明细</v-btn
              >
              <v-btn class="ml-2" @click="goTo(1)">返回页面</v-btn></v-col
            >
            <v-col sm="2"> <v-spaceer></v-spaceer></v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
    </div>
    <div v-if="flag && step > 0" class="mt-8">
      <v-stepper :value="step" flat>
        <v-stepper-header>
          <v-stepper-step step="1" :complete="step > 1">
            生成映射表
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="step > 2">
            下发映射表</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="step == 3"
            >下发IPv6资源池</v-stepper-step
          >
        </v-stepper-header>
      </v-stepper>
      <Result
        :input="{
          flag: flag,
          progress: progress,
          message: message,
          step: step,
        }"
      />
    </div>
  </v-card>
</template>

<script>
import Result from "./result.vue";
export default {
  components: { Result },
  data() {
    return {
      headers: [
        {
          text: "序号",
          align: "start",
          sortable: false,
          value: "no",
        },
        { text: "domain", value: "domain" },
        { text: "dst_addr", value: "dst_addr" },
        { text: "src_addr", value: "src_addr" },
        { text: "ttl", value: "ttl" },
        // { text: "操作", value: "actions", sortable: false },
      ],
      index: 0,
      dataList: [],
      dialog: false,
      loading: false,
      loading1: false,
      btnLoading: false,
      btnLoading1: false,
      btnLoading2: false,
      btnLoading3: false,
      btnLoading4: false,
      btnLoading5: false,
      btnLoading6: false,
      btnLoading7: false,
      progress: 0,
      query: {
        no: "",
        ipv4: "",
      },
      message: "",
      step: 0,
      flag: false,
      status: false,
    };
  },
  methods: {
    getList(index) {
      this.flag = false;
      this.status = false;
      this.index = index;
      let url =
        index != 0
          ? index != 1
            ? "post-dns-cache-list"
            : "get-dns-cache-list"
          : "update-dns-list";
      if (index == 0) {
        this.btnLoading0 = true;
      } else if (index == 1) {
        this.btnLoading1 = true;
      } else if (index == 2) {
        this.btnLoading2 = true;
      }
      this.$axios
        .get(`/trunkserver/assets/${url}/`)
        .then((res) => {
          console.log(res);
          if (res.data.list && res.data.list.length > 0) {
            if (index == 2) {
              let list = [];
              res.data.list.forEach((element) => {
                list.push({
                  domain: element.domain,
                  dst_addr: element.dst_ip_addr_v6,
                  src_addr: element.src_ip_addr_v4,
                  ttl: element.ttl,
                });
              });
              this.dataList = list;
            } else {
              this.dataList = res.data.list;
            }
          } else {
            this.dataList = [];
            this.total = 0;
          }
          if (index == 0) {
            this.btnLoading0 = false;
          } else if (index == 1) {
            this.btnLoading1 = false;
          } else {
            this.btnLoading2 = false;
          }
        })
        .catch((err) => {
          if (index == 0) {
            this.btnLoading0 = false;
          } else if (index == 1) {
            this.btnLoading1 = false;
          } else {
            this.btnLoading2 = false;
          }
          console.log(err);
        });
    },
    download() {
      window.location.href = "/trunkserver/assets/download-rules-mapping/";
    },
    gend() {
      this.$router.push({
        path: "/domain/gend",
      });
    },
    postRules() {
      this.flag = true;
      this.step = 1;
      this.getRules0(0, false);
    },
    getRules0(index, status) {
      if (index == 0) {
        this.btnLoading4 = true;
      } else {
        this.btnLoading5 = true;
      }
      this.status = status;
      if (status) {
        this.flag = false;
      }

      this.loading1 = true;
      this.progress = 0;
      let url = index == 0 ? "gen-rules-mapping" : "post-rules-mapping";
      this.$axios
        .post(`/trunkserver/assets/${url}/`)
        .then((res) => {
          if (res.data && res.data.code == 0) {
            if (index == 0) {
              this.btnLoading4 = false;
            } else {
              this.btnLoading5 = false;
            }
            this.loading1 = false;
            this.message = index == 0 ? "生成成功" : "下发成功";
            console.log(status);
            if (!status) {
              this.step = this.step + 1;
              this.getRules1(1, false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading1 = false;
          if (index == 0) {
            this.btnLoading4 = false;
          } else {
            this.btnLoading5 = false;
          }
        });
      var id = setInterval(() => {
        this.getProgress(index, id);
      }, 1000);
    },
    getRules1(index, status) {
      if (index == 0) {
        this.btnLoading4 = true;
      } else {
        this.btnLoading5 = true;
      }
      this.status = status;
      if (status) {
        this.flag = false;
      }
      this.loading1 = true;
      this.progress = 0;
      let url = index == 0 ? "gen-rules-mapping" : "post-rules-mapping";
      this.$axios
        .post(`/trunkserver/assets/${url}/`)
        .then((res) => {
          if (res.data && res.data.code == 0) {
            if (index == 0) {
              this.btnLoading4 = false;
            } else {
              this.btnLoading5 = false;
            }
            this.loading1 = false;
            this.message = index == 0 ? "生成成功" : "下发成功";
            console.log(status);
            if (!status) {
              this.step = this.step + 1;
              this.post();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading1 = false;
          if (index == 0) {
            this.btnLoading4 = false;
          } else {
            this.btnLoading5 = false;
          }
        });
      var id = setInterval(() => {
        this.getProgress(index, id);
      }, 1000);
    },
    getProgress(index, id) {
      let url =
        index == 0
          ? "gen-rules-mapping-progress"
          : "post-rules-mapping-progress";
      this.$axios
        .get(`/trunkserver/assets/${url}/`)
        .then((res) => {
          this.progress = res.data;
          if (res.data == 100) {
            clearInterval(id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    post() {
      this.btnLoading7 = true;
      this.$axios
        .post(`/trunkserver/assets/post-ipv6-addrs/`)
        .then((res) => {
          if (res.data && res.data.code == 0) {
            this.message = "下发成功";
            this.btnLoading7 = false;
          }
        })
        .catch((err) => {
          this.btnLoading7 = false;
          console.log(err);
        });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style scoped>
.main {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.query {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
</style>
