<template>
  <v-card flat>
    <v-card-text>
      <v-progress-linear
        v-if="input.step != 3"
        v-model="input.progress"
        height="15"
      >
        <strong>{{ input.progress }}%</strong>
      </v-progress-linear>
      <v-row v-if="input.progress == 100 || input.step > 2">
        <v-col sm="2">
          <v-spaceer name="spaceer"></v-spaceer>
        </v-col>
        <v-col class="text-center">
          <v-icon style="color: green" size="80"
            >mdi-checkbox-marked-circle</v-icon
          >
          <div class="text-h5 black--text">{{ input.message }}</div>
        </v-col>
        <v-col sm="2">
          <v-spaceer name="spaceer1"></v-spaceer>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    input: {
      message: "",
      flag: "",
      progress: "",
      step: 0,
    },
  },
  components: {},
  name: "ResultSuccess",
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
<style scoped>
.main {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.query {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
</style>
